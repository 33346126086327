// src/Nav.js

import React from 'react'
import './_nav.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Nav() {
  return (
    <div className="navigation" id="navigation">
      <ul class="nav nav-pills" role="tablist">
        <li class="nav-item">
            <a href="#home" class="nav-link">Home</a>
        </li>
        <li class="nav-item">
            <a href="#twitch" class="nav-link">Twitch</a>
        </li>
        <li class="nav-item">
            <a href="#about" class="nav-link">About</a>
        </li>
        <li class="nav-item">
            <a href="#works" class="nav-link">Works</a>
        </li>
        <li class="nav-item">
            <a href="#contact" class="nav-link">Contact</a>
        </li>
        <li class="nav-item pl-2">
            <a href="https://streamlabs.com/pokahydee1" class="nav-link btn-donate" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={["faCheckSquare", "heart"]} />
              Donate!
            </a>
        </li>
      </ul>
    </div>
  )
}

export default Nav