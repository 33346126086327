// src/Works.js

import React from 'react'
import { Slide } from 'react-slideshow-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-slideshow-image/dist/styles.css';
import './_works.scss';

import derp from '../assets/images/emotes/derp.png';
import innocent from '../assets/images/emotes/innocent.png';
import lewd from '../assets/images/emotes/lewd.png';
import love from '../assets/images/emotes/love.gif';
import queen from '../assets/images/emotes/queen.png';
import UwU from '../assets/images/emotes/UwU.png';
import eyes from '../assets/images/emotes/eyes.png';
import mittens from '../assets/images/emotes/mittens.png';
import socks from '../assets/images/emotes/socks.png';
import stinky from '../assets/images/emotes/stinky.png';
import graphic from '../assets/images/graphics.png';
import overlay from '../assets/images/overlay.png';
import aurora from '../assets/video/aurora-short-2.mp4';
import auroraPoster from '../assets/images/aurora-poster.png';
import nyancat from '../assets/video/brb-screen.mp4';
import nyanPoster from '../assets/images/nyan-poster.png';

const artwork = [{
        url: derp,
        caption: 'Derp Emote'
    }, {
        url: innocent,
        caption: 'Innocent Emote'
    }, {
        url: lewd,
        caption: 'Lewd Emote'
    }, {
        url: love,
        caption: 'Love Emote'
    }, {
        url: queen,
        caption: 'Queen Emote'
    }, {
        url: UwU,
        caption: 'UwU Emote'
    }, {
        url: eyes,
        caption: 'Eyes Emote'
    }, {
        url: stinky,
        caption: 'Stinky Emote'
    }, {
        url: mittens,
        caption: "Mittens Emote"
    }, {
        url: socks,
        caption: 'Socks Emotes'
    }];

const graphics = [
    {
        url: graphic,
        caption: 'Badges, Flairs, and Logos'
    }, {
        url: overlay,
        caption: 'Overlays'
    }];

const videos = [{
        url: aurora,
        poster: auroraPoster,
        caption: "Aurora"
    }, {
        url: nyancat,
        poster: nyanPoster,
        caption: "Nyan cat BRB"
    }];

function Works() {
  return (
    <div class="works-container" id="works">
        <a name="works" title="Works" href="javascript:void(0)"></a>
        <h2>Works</h2>
        <p>
            I am capable of doing a variety of different works, including drawing, graphic design work, development work, and video editing and motion graphics. Below
            illustrates some of my work.
        </p>

        <div class="works">
            <div class="work-type">
                <h3>Artwork</h3>
                <div class="slide-container">
                    <Slide>
                    {artwork.map((slideImage, index)=> (
                        <div className="each-slide videos" key={index}>
                            <img src={slideImage.url} alt={slideImage.caption} class="img-responsive" />
                        </div>
                    ))} 
                    </Slide>
                </div>
            </div>
            <div class="work-type">
                <h3>Graphic Work</h3>
                <div class="slide-container">
                    <Slide>
                        {graphics.map((slideImage, index) => (
                            <div className="each-slide" key={index}>
                                <img src={slideImage.url} alt={slideImage.caption} class="img-responsive" />
                            </div>
                        ))}
                    </Slide>
                </div>
            </div>
            <div class="work-type">
                <h3>Video Work</h3>
                <div class="slide-container">
                    <Slide>
                        {videos.map((slideVideo, index) => (
                            <div className="each-slide" key={index}>
                                <video class="video" id={index} autoPlay muted loop poster={slideVideo.poster}>
                                    <source src={slideVideo.url} type="video/mp4" />
                                </video>
                            </div>
                        ))}
                    </Slide>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Works