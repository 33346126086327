// src/Contact.js

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './_contact.scss';

function Contact() {
  return (
    <div class="contact-container" id="contacts">
        <div class="row buttons text-bg-gray p-4 align-items-center justify-content-center">
            <p class="lead">
                Email me at <a href="mailto:pokahydeetv@gmail.com" class="link-secondary">pokahydeetv@gmail.com</a>.
            </p>

            <div class="col col-auto mt-2 mb-2">
                <a href="https://twitter.com/Pokahydee" class="btn btn-twitter" target="_blank">
                    <FontAwesomeIcon icon={["fab", "twitter"]} />
                    Follow Me on Twitter!
                </a>
            </div>
            
            <div class="col col-auto mt-2 mb-2">
                <a href="https://twitch.tv/Pokahydee" class="btn btn-twitch" target="_blank">
                    <FontAwesomeIcon icon={["fab", "twitch"]} />
                    Follow Me on Twitch!
                </a>
            </div>
            
            <div class="col col-auto mt-2 mb-2">
                <a href="https://www.instagram.com/pokahydee/" class="btn btn-instagram" target="_blank">
                    <FontAwesomeIcon icon={["fab", "instagram"]} />
                    Follow My Instagram!
                </a>
            </div>
            
            <div class="col col-auto mt-2 mb-2">
                <a href="https://discord.gg/86Ds6Ep" class="btn btn-discord" target="_blank">
                    <FontAwesomeIcon icon={["fab", "discord"]} />
                    Join My Discord!
                </a>
            </div>
        </div>

        <div class="row">
            <div class="col col-6">
                <h3>Twitter Feed</h3>
                <a class="twitter-timeline" data-width="500" data-height="400" data-theme="dark" href="https://twitter.com/Pokahydee?ref_src=twsrc%5Etfw">
                    Tweets by Pokahydee
                </a>
                <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
            </div>
        </div>
    </div>
  )
}

export default Contact