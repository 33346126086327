import Title from './Title/Title.js';
import Nav from './Nav/Nav.js';
import Twitch from './Twitch/Twitch.js';
import About from './About/About.js';
import Works from './Works/Works.js';
import ContactForm from './ContactForm/ContactForm.js';
import Contact from './Contact/Contact.js';
import Footer from './Footer/Footer.js';
import logo from './assets/images/logo.png';
//import './App.css';
import './assets/scss/root.scss';
import './assets/scss/main.scss';

function App() {
  return (
    <div className="application">
      <header className="header" id="header">
        <div class="container-fluid text-bg-dark">
          <div class="pt-2 pb-2 row align-items-end justify-content-between">
            <div class="col col-auto">
              <img src={logo} className="logo" alt="logo" />
            </div>

            <div class="col col-auto">
              <Nav />
            </div>
          </div>
        </div>
      </header>
      <div class="header-shim"></div>

      <div class="content" data-bs-spy="scroll" data-bs-target="#navigation" data-bs-smooth-scroll="true">
        <Title />

        <div class="container-fluid">
          <Twitch />
        </div>

        <div class="about pt-4 pb-4">
          <div class="container-fluid">
            <About />
          </div>
        </div>

        <div class="container-fluid pt-4 pb-4">
          <Works />
        </div>

        {/* <div class="container-fluid pt-4 pb-4">
          <ContactForm />
        </div> */}

        <div class="container-fluid pt-4 pb-4">
          <a name="contact" title="Contact" href="javascript:void(0)"></a>
          <h2>Contact</h2>
          
          <Contact />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
