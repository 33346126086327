// src/Emotes.js

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Marquee from 'react-fast-marquee';

import './_emotes.scss';
import derp from '../assets/images/emotes/derp.png';
import innocent from '../assets/images/emotes/innocent.png';
import lewd from '../assets/images/emotes/lewd.png';
import love from '../assets/images/emotes/love.gif';
import queen from '../assets/images/emotes/queen.png';
import UwU from '../assets/images/emotes/UwU.png';
import eyes from '../assets/images/emotes/eyes.png';
import mittens from '../assets/images/emotes/mittens.png';
import socks from '../assets/images/emotes/socks.png';
import stinky from '../assets/images/emotes/stinky.png';

function Emotes() {
  return (
    <div className="emotes-container mt-4" id="emotes">
        <Marquee gradientColor={[20, 22, 26]} speed={50} gradientWidth={48}>
            <div class="emotes">
                <div class="emote">
                    <img src={innocent} alt="Innocent" />
                </div>
                <div class="emote">
                    <img src={lewd} alt="Lewd" />
                </div>
                <div class="emote">
                    <img src={UwU} alt="UwU" />
                </div>
                <div class="emote">
                    <img src={queen} alt="Queen" />
                </div>
                <div class="emote">
                    <img src={derp} alt="Derp" />
                </div>
                <div class="emote">
                    <img src={love} alt="Love" />
                </div>
                <div class="emote">
                    <img src={eyes} alt="Eyes" />
                </div>
                <div class="emote">
                    <img src={mittens} alt="Mittens" />
                </div>
                <div class="emote">
                    <img src={socks} alt="Socks" />
                </div>
                <div class="emote">
                    <img src={stinky} alt="Stinky" />
                </div>
            </div>
        </Marquee>
    </div>
  )
}

export default Emotes