// src/About.js

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './_about.scss';
import photo from '../assets/images/about.png';

function About() {
  return (
    <div className="about-container pt-4 pb-4">
        <a name="about" title="About" id="about" href="javascript:void(0)"></a>
        <h2>About</h2>

        <div class="row">
            <div class="col col-12 col-sm-6 col-lg-4">
                <img src={photo} alt="Photo" class="img-responsive" />
            </div>

            <div class="col col-12 col-sm-6 col-lg-8">
                <h3>About Pokahydee</h3>
                <p>
                  I'm a variety streamer that does everything from Minecraft, to Rimworld, to possible art streams.
                  I'm a single mom of a 13 year old that started streaming in order to get over some social anxiety.
                  We are a safe place for anyone and occasionally have discussions about mental health (I am not a counselor).
                  If you want a place to just chill and chat while I play games, this is the spot.
                </p>

                <p class="lead">
                  Streamer, Programmer, Artist, Author, and Friend.
                </p>

                <p class="lead">
                  <FontAwesomeIcon icon={["faCheckSquare", "star-and-crescent"]} />
                </p>
            </div>
        </div>
    </div>
  )
}

export default About