// src/Title.js

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './_title.scss';
import video from '../assets/video/aurora-short-2.mp4';
import poster from '../assets/images/aurora-poster.png';

function Title() {
  return (
    <div className="page-title">
      <a name="home" title="Home" id="home" href="javascript:void(0)"></a>
      <h1>Pokahydee &ndash; Streamer, Programmer, Artist</h1>

      <div class="mt-4 buttons row">
        <div class="col col-12 col-md-auto">
          <a href="https://www.twitch.tv/pokahydee/" target="_blank" class="btn btn-twitch">
            <FontAwesomeIcon icon={["fab", "twitch"]} />
            Follow on Twitch!
          </a>
        </div>

        <div class="col col-12 col-md-auto">
          <a href="https://discord.gg/86Ds6Ep" target="_blank" class="btn btn-discord">
            <FontAwesomeIcon icon={["fab", "discord"]} />
            Join the Discord!
          </a>
        </div>
      </div>

      <div class="video-background">
        <video id="background-video" autoPlay muted loop poster={poster}>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

export default Title