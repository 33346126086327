// src/Twitch.js

import React, { useRef } from 'react'
import './_twitch.scss';
import Emotes from '../Emotes/Emotes.js';

function Twitch () {
  const embed = useRef(); // We use a ref instead of state to avoid rerenders.
  
  const handleReady = (e) => {
    embed.current = e;
  };

  return (
    <div className="twitch-container pt-4 pb-4" id="twitchContainer">
        <a name="twitch" title="Twitch" id="twitch" href="javascript:void()"></a>

        <h2>Twitch</h2>

        <div class="player">
          <div class="player-container">
            <iframe src="https://player.twitch.tv/?channel=pokahydee&parent=pokahydee.com&parent=www.pokahydee.com" height="100%" width="100%"></iframe>
          </div>
        </div>

        <Emotes />
    </div>
  )
}

export default Twitch