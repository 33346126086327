// src/Footer.js

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './_footer.scss';

function Footer() {
  return (
    <footer className="text-bg-gray">
        <div class="container-fluid p-4">
            <div className="row justify-content-between align-items-center">
                <div class="col col-auto">
                    Copyright &copy; {new Date().getFullYear()} Pokahydee
                </div>
                <div class="col col-auto">
                    <ul class="list-inline social-links mb-0">
                        <li class="list-inline-item">
                            <a href="mailto:pokahydeetv@gmail.com" class="link-dark">pokahydeetv@gmail.com</a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://twitter.com/Pokahydee" class="link link-twitter" target="_blank">
                                <FontAwesomeIcon icon={["fab", "twitter"]} />
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://twitch.tv/Pokahydee" class="link link-twitch" target="_blank">
                                <FontAwesomeIcon icon={["fab", "twitch"]} />
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://www.instagram.com/pokahydee/" class="link link-instagram" target="_blank">
                                <FontAwesomeIcon icon={["fab", "instagram"]} />
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="https://discord.gg/86Ds6Ep" class="link link-discord" target="_blank">
                                <FontAwesomeIcon icon={["fab", "discord"]} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer